import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, Card, Skeleton } from '@mui/material'
// import { AnimatedProgressProvider } from '../../../helpers'
import { CountUpAnimation } from '../../../helpers'
import { easeCubicInOut } from 'd3-ease'

const PREFIX = 'MusicTile'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	title: `${PREFIX}-title`,
	count: `${PREFIX}-count`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.card}`]: {
		width: '100%',
		height: '100%',
		aspectRatio: '1 / 1', //Automatically sets the height equal to the width
		borderRadius: 0,
		boxShadow: `${'2px 3px 7px 2px rgba(0,0,0,0.1)'}`
	},
	[`& .${classes.grid}`]: {
		width: '100%',
		height: '100%'
	},
	[`& .${classes.title}`]: {
		height: '100%',
		paddingLeft: 5,
		textAlign: 'center'
	},
	[`& .${classes.count}`]: {
		paddingLeft: 5,
		textAlign: 'center',
		fontWeight: 400,
		[breakpoints.up('xs')]: {
			fontSize: 44
		},
		[breakpoints.up('md')]: {
			fontSize: 42
		},
		[breakpoints.up('lg')]: {
			fontSize: 54
		},
		[breakpoints.up('xl')]: {
			fontSize: 58
		}
	}
}))

const ScrobbleTile = ({ title, count }) => {
	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				<Grid container direction='column' alignItems='center' className={classes.grid}>
					<Grid item xs={3} container direction='column' justifyContent='center' alignItems='center'>
						<Grid item>
							<Typography variant='subtitle1' className={classes.title}>
								{count > -1 ? title : <Skeleton variant='text' animation='wave' width={50} />}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={9} container direction='column' alignItems='center'>
						<Grid item xs={3} />
						<Grid item>
							{count > -1 ? (
								<CountUpAnimation valueStart={0} valueEnd={count} duration={1.2} easingFunction={easeCubicInOut}>
									{(value) => {
										const roundedValue = Math.round(value)
										return (
											<Typography variant='h3' className={classes.count}>
												{roundedValue}
											</Typography>
										)
									}}
								</CountUpAnimation>
							) : (
								<Skeleton variant='text' animation='wave' width={60} height={65} sx={{ marginTop: '-35%' }} />
							)}
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Root>
	)
}

export default ScrobbleTile
