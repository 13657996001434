import { useEffect, useState } from 'react'

// const easeOutQuad = (t) => t * (2 - t)
const frameDuration = 1000 / 60

const CountUpAnimation = ({ children, valueStart = 0, valueEnd, easingFunction, duration = 2000 }) => {
	const countTo = parseInt(valueEnd)
	const [count, setCount] = useState(valueStart)
	const animate = () => {
		let frame = 0
		const totalFrames = Math.round((duration * 1000) / frameDuration)
		const counter = setInterval(() => {
			frame++
			const progress = easingFunction(frame / totalFrames)
			setCount(countTo * progress)

			if (frame === totalFrames) {
				clearInterval(counter)
			}
		}, frameDuration)
	}

	useEffect(() => {
		if (count < valueEnd) {
			animate()
		}
		// eslint-disable-next-line
	}, [valueEnd])

	return children(Math.floor(count))
}

export default CountUpAnimation
